.projectsSection{
    .projectsTitle{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 24px;
        h1{
            font-size: 24px;
            letter-spacing: 6px; 
        }
    }
    .projectsContainer{
        display: grid;
        grid-template-columns: 1fr 1fr;
        a{
            text-decoration: none;
            color: #3F3F3F;

        }
    }
    .projectsCard{
        background-color: white;
        margin: 24px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.15); 
        img{
            width: 520px;
            height: auto;
            border-radius: 24px;
            margin: 18px 0;
        }
        p{
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .projectsCard:hover{
        cursor: pointer;
        border: 1.5px solid #921C57;
    }
    .buttonContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 24px;
        a{
            cursor: pointer;
            display: flex;
            align-items: center;
            background-color: #921C57;
            color:white;
            border-radius: 20px;
            border: none;
            padding: 5px 24px;
            font-family: Poppins;
            font-size: 14px;
            text-decoration: none;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.248); 
            img{
                margin: 0 12px;
                width: 20px;
            }
        }

    }
}

/*Mediaqueries*/

@media screen and (max-width: 768px){
    .projectsSection{
        .projectsContainer{
            grid-template-columns: 1fr;
        }
    }
}
@media screen and (max-width: 425px){
    .projectsSection{
        .projectsCard{
            img{
                width: 320px;
            }
        }
    }
}
