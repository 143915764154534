.experienceSection{
    background-color:#921C57;
    color: white;
    margin-top: -90px ;
    padding: 24px;
}
.experienceTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    h1{
        font-size: 24px;
        letter-spacing: 6px; 
    }
}
.experienceJobs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 60px;
}
.experienceText{
    padding: 32px;
    h3{
        letter-spacing: 2px;
    }
}

/*Mediaqueries*/

@media screen and (max-width: 768px){
    .experienceSection{
        margin-top: -40px;
        .experienceJobs{
           grid-template-columns: 1fr; 
        }
    }
}
@media screen and (max-width: 425px){
    .experienceSection{
        margin: 18px 0;
        .experienceJobs{
            margin: 24px;
        }
        .experienceTitle{
            padding: 24px;
        }
        .experienceText{
            padding: 12px;
        }
    }
}