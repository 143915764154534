@import url('https://fonts.cdnfonts.com/css/poppins');

.App {
  font-family: 'Poppins', sans-serif;
}
.aptitudesSection{
  background-image: url(./assets/background_habilidades.jpg);
    background-size: auto;
    background-position: 32px;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 80px 0 ;

    .aptitudesTitle{
      display: flex;
      justify-content: center;
      align-items: center;
      h1{
        color:#921C57;
        letter-spacing: 6px;
        font-size: 24px;
      }
    }
    .aptitudesText{
      border-left: 8px solid #3C9BA4;
      height: 520px;
      padding-left: 32px;
      margin-right: 180px;
      h3{
        font-size: 18px;
        color:#333333;
        letter-spacing: 4px;
      }
      p{
        font-size: 16px;
      }
    }
}
.habilitiesSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    font-size: 24px;
    color: #333333;
    letter-spacing: 6px;
  }
  .habilitiesCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 80px;
    div{
      background-color: #FFFFFF;
      box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      margin: 0 50px;
      padding:32px;
      text-align: center;
      img{
        width:46px ;
      }
      h4{
        color: #921C57;
        font-size: 18px;
        letter-spacing: 4px;
      }
      p{
        font-size: 12px;
      }

    }
  }
}
.skillSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    font-size: 24px;
    color: #333333;
    letter-spacing: 6px;
    margin-bottom: 60px;
  }

  .imageSkills{
    div{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    img{
      margin:24px;
      width: 70 px;
    }

  }

}
.academicSection{
  background-image: url(./assets/background_formacion.jpg);
    background-size: auto;
    background-position: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 820px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 80px 0;
    .academicTitle{
      display: flex;
      justify-content: center;
      margin-top: 160px;
      h1{
        font-size: 24px;
        color: #333333;
        letter-spacing: 6px;
      }
    }
    .academicText{
      margin: 0 120px 0 0;
      div{
        margin: 60px 0;
      }
      h2{
        font-size: 24px;
        color: #333333;
        letter-spacing: 5px;
      }
      h4{
        font-size: 18px;
        color: #921C57;
        font-weight: 400;
        margin: 8px 0;
      }
      p{
        font-size: 16px;
        color: #656565;
        margin: 4px 0;
      }
      .academicTextRight{
        text-align: right;
      }
    }
}
.contactSection{
  margin: 100px 0;
  .contactTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    h1{
      color:#3F3F3F;
      letter-spacing: 6px;
      font-size: 24px;
    }
  }
  .contactContainer{

    margin: 0 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .contactEmail{
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #333333;
      img{
        margin: 0 20px;
      }
    }
    .contactSocial{
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        background-color: #921C57;
        margin: 0 40px;
        padding: 24px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
      }
    }
  }
}

/*Mediaqueries*/

@media screen and (max-width: 768px){
  .aptitudesSection{
    grid-template-columns: 1fr;
    margin: 40px;
    .aptitudesTitle{
      justify-content: left;
      border-bottom: 8px solid #3C9BA4;
    }
    .aptitudesText{
      border: none;
      margin: 20px 0px 40px 0px;
      padding: 0px;
      height: auto;
      h3{
        font-size: 16px;
      }
      p{
        font-size: 14px;
      }
    }
  }
  .habilitiesSection{
    .habilitiesCards{
      margin: 24px;
      grid-template-columns: 1fr 1fr;
      div{
        margin: 24px;
        padding: 24px;
      }
      img{
        width: 24px;
      }
    }
  }
  .skillSection{
    .imageSkills{
      div{
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
  .academicSection{
    grid-template-columns: 1fr;
    height: auto;
    background-position: -80px;
    text-align: right;
    margin:60px 0px;
    .academicTitle{
      justify-content: right;
      margin: 40px 32px;
    }
    .academicText{
      div{
        margin: 32px 0;
      }
      display: flex;
      flex-direction: column;
      justify-content: right;
      margin: 32px;

    }
  }
  .contactSection{
    .contactTitle{
      margin: 24px 0;
    }
    .contactContainer{
      grid-template-columns: 1fr;
      margin: 24px 0;
      .contactEmail{
        justify-content: center;
        margin-bottom: 32px;
      }
    }
  }
}

@media screen and (max-width: 425px){
  .aptitudesSection{
    margin: 24px;
    .aptitudesTitle{
      justify-content: center;
    }
    .aptitudesText{
      text-align: center;
      margin: 32px 0px 32px 0px;
      div{
        margin: 32px 0;
      }
    }
  }
  .habilitiesSection{
    justify-content: center;
    text-align: center;
    .habilitiesCards{
      grid-template-columns: 1fr;
    }
  }
  .skillSection{
    .imageSkills{
      div{
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .academicSection{
    background-image: none;
    margin:18px 24px ;
    text-align: left;
    .academicTitle{
      margin: 24px;
    }
    .academicText{
      margin: 24px;
      div{
        margin: 20px 0;
      }
      h2{
        margin: 8px 0;
      }
      .academicTextRight{
      text-align: left;
    }
    }
    
  }
  .contactSection{
    .contactContainer{
    grid-template-columns: 1fr;
    margin: 32px 0;
    .contactEmail{
      img{
        margin: 0 8px;
      }
    }
    .contactSocial{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 40px;
      a{
        margin: 24px;
      }
    }
  }
  }
}